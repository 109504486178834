import React, { Component } from 'react'
import PropTypes from 'prop-types'

const AppError = React.lazy(() => import(/* webpackChunkName: "AppError" */ 'core/scenes/AppError'))

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    // eslint-disable-next-line react/no-unused-state
    this.state = { hasError: false, error: null, errorInfo: null, eventId: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error, errorInfo: '' }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      // eslint-disable-next-line react/no-unused-state
      error,
      // eslint-disable-next-line react/no-unused-state
      errorInfo,
    })

    import(
      /* webpackChunkName: "Sentry" */
      '@sentry/browser'
    ).then((Sentry) => {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        // eslint-disable-next-line react/no-unused-state
        this.setState({ eventId })
      })
    })
  }

  render() {
    const { hasError } = this.state
    const { level, children } = this.props

    if (hasError) {
      return (
        <React.Suspense fallback={null}>
          <AppError errorLevel={level} />
        </React.Suspense>
      )
    }
    return children
  }
}

ErrorBoundary.propTypes = {
  level: PropTypes.oneOf([
    'app',
    'core',
    'module',
    'module-dealership',
    'module-client',
    'module-user',
    'scene',
  ]).isRequired,
  children: PropTypes.element.isRequired,
}

export default React.memo(ErrorBoundary)
