class SelectOption {
  constructor(id, value) {
    this.id = id
    this.value = value
  }

  get asJson() {
    return { code: this.id, description: this.value }
  }

  static fromJson({ code, id, name, description, value, label }) {
    const fillValue = name || description || value || label
    const fillId = code || id || fillValue

    if (fillId !== null && fillValue != null) {
      return new SelectOption(fillId, fillValue)
    }

    return null
  }
}

export default SelectOption
