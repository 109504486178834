import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'
import { routes } from './routes'

const Router = () => {
  return (
    <BrowserRouter>
      <React.Suspense fallback={null}>
        <Routes>
          {routes.map((route) => (
            <Route
              element={route.component}
              key={route.path}
              path={route.path}
              name={route.path}
              exact={route.exact}
            />
          ))}
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default observer(Router)
