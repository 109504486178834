class Document {
  constructor(url) {
    this.url = url
  }

  static fromJson({ downloadLink }) {
    return new Document(downloadLink)
  }
}

export default Document
