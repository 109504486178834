import React, { useEffect } from 'react'
import ErrorBoundary from 'core/scenes/ErrorBoundary'
import AppProviders from 'core/providers'
import 'core/util/i18n'
import Router from 'core/routing'
import './styles/_base.module.scss'
import axiosInterceptors from './util/axiosInterceptors'

const MaintenanceMode = React.lazy(() =>
  import(
    /* webpackChunkMode: "MaintenanceMode" */
    'core/scenes/MaintenanceMode'
  )
)

const App = () => {
  useEffect(() => {
    if (!(process.env.REACT_APP_MAINTENANCE_MODE === 'true')) {
      window.addEventListener('load', () => {
        setTimeout(() => {
          import('core/services/ThirdPartyServices').then((thirdPartyServices) =>
            thirdPartyServices.default.init()
          )
          axiosInterceptors()
        }, 1200)
      })
    }
  }, [process.env.REACT_APP_MAINTENANCE_MODE])

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return (
      <React.Suspense fallback={null}>
        <MaintenanceMode />
      </React.Suspense>
    )
  }

  return (
    <ErrorBoundary level="core">
      <AppProviders>
        <Router />
      </AppProviders>
    </ErrorBoundary>
  )
}

export default App
