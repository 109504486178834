import axios from 'axios'
import i18next from 'i18next'
import { API_URL } from 'core/services/config'
import { LESS_THAN_SIX_MONTHS, LESS_THAN_TWELVE_MONTHS } from 'core/constants'
import DealershipAuthService from 'modules/Dealership/services/DealershipAuthService'
import DealershipOption from 'modules/Dealership/models/DealershipOption'
import PrivacyAcceptance from 'core/models/PrivacyAcceptance'
import JWT from 'core/models/JWT'
import Location from 'modules/Dealership/models/Location'
import Document from 'modules/Dealership/models/Document'
import DealershipInsurances from 'modules/Dealership/models/DealershipInsurances'
import InsuranceCarData from 'modules/Dealership/models/InsuranceCarData'
import Settings from 'core/models/Settings'
import SelectOption from 'core/models/SelectOption'

const DEALERSHIP_CREDIT_UUID = 'dealership-credit-uuid'
const DEALER_NAME = 'dealer-name'

class DealershipDataService {
  static http() {
    const http = axios.create()
    http.interceptors.request.use(
      (config) => {
        const sessionToken = DealershipAuthService.getSessionId()
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }

        if (sessionToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${sessionToken}`
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    http.interceptors.response.use(
      (response) => {
        if (response?.data?.meta?.token?.accessToken && response?.data?.meta?.token?.expiresAt) {
          const token = JWT.fromJson(response.data.meta.token)

          // updates jwtoken if needed
          DealershipAuthService.setSessionId(token.accessToken, token.expiration)
          window.location.reload()
        }

        return response
      },
      (error) => {
        // Clear session data and reload
        if (
          error?.response?.status === 401 ||
          error?.response?.data?.error?.code === 'invalid_token'
        ) {
          DealershipAuthService.deauthenticate()
          window.location.reload()
        }
        return Promise.reject(error)
      }
    )

    return http
  }

  static creditUUID = () =>
    DealershipDataService.getCreditUUID() ? `/${DealershipDataService.getCreditUUID()}` : ''

  static getCreditUUID() {
    return JSON.parse(sessionStorage.getItem(DEALERSHIP_CREDIT_UUID))
  }

  static setCreditUUID(value) {
    sessionStorage.setItem(DEALERSHIP_CREDIT_UUID, JSON.stringify(value))
  }

  static persistDealerName(value) {
    localStorage.setItem(DEALER_NAME, JSON.stringify(value))
  }

  static removeDealerName() {
    localStorage.removeItem(DEALER_NAME)
  }

  static getPersistedDealerName() {
    const data = localStorage.getItem(DEALER_NAME)

    if (data) {
      return JSON.parse(data)
    }

    return null
  }

  static removePersistedData() {
    DealershipDataService.removeDealerName()
    DealershipDataService.clearCreditUUID()
  }

  static clearCreditUUID() {
    sessionStorage.removeItem(DEALERSHIP_CREDIT_UUID)
  }

  static getDealershipOptions() {
    return DealershipDataService.http()
      .get(`${API_URL}/sellers/dealerships`)
      .then((res) => {
        return res.data.data.map((option) => DealershipOption.fromJson(option))
      })
  }

  static parseSessionData(session) {
    return {
      ...session,
      personalData: session.personSnapshot,
      ageAtJob: session?.financialSnapshot?.ageAtJob
        ? SelectOption.fromJson(session?.financialSnapshot?.ageAtJob)
        : null,
      workingMode: session?.financialSnapshot?.employeeType
        ? SelectOption.fromJson(session?.financialSnapshot?.employeeType)
        : null,
      additionalPersonalData: session.financialSnapshot,
      simulation: !Array.isArray(session.simulation) ? session.simulation : null,
      familyMember: session.cosigner,
      selectedInstallment: true,
      dealership: session.dealership,
      branch: session.branch,
    }
  }

  static getDataSession() {
    return DealershipDataService.http()
      .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}`)
      .then((r) => {
        const response = r.data.data
        return DealershipDataService.parseSessionData(response)
      })
  }

  static getPossibleCurp(data) {
    return DealershipDataService.http()
      .post(
        `${API_URL}/sessions${DealershipDataService.creditUUID()}/generate-curp
`,
        data
      )
      .then((res) => res.data.data)
  }

  static getPlaceOfBirthOptions() {
    return DealershipDataService.http()
      .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}/states`)
      .then((res) => res.data.data.states.map((option) => SelectOption.fromJson(option)))
  }

  static quote(carData) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/insurances`, carData)
      .then((res) => {
        return DealershipInsurances.fromJson(res?.data?.data)
      })
  }

  static sendData({ ...data }) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/data`, { ...data })
      .then((res) => res.data.data)
  }

  static getAmortizationTableDocument(insuranceCashPayment, dealerName) {
    return DealershipDataService.http()
      .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}/amortizationTable`, {
        params: { insuranceCashPayment, dealerName },
      })
      .then((res) => {
        DealershipDataService.persistDealerName(dealerName)
        return Document.fromJson(res.data.data)
      })
  }

  static sendEmail() {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/draft`)
      .then((res) => res)
  }

  static getWorkTypeOptions() {
    return DealershipDataService.workTypeOptions
  }

  static getWorkTimeOptions(option) {
    if (!option) {
      return []
    }

    if (option.id === 'dependent') {
      return DealershipDataService.workDependentTimeOptions
    }

    return DealershipDataService.workIndependentTimeOptions
  }

  static workTypeOptions = [
    { id: 'dependent', name: i18next.t('additionalPersonalData:dependent') },
    { id: 'independent', name: i18next.t('additionalPersonalData:independent') },
  ]

  static genderOptions = [
    { id: 'male', value: i18next.t('male') },
    { id: 'female', value: i18next.t('female') },
  ]

  static workDependentTimeOptions = [
    {
      id: LESS_THAN_SIX_MONTHS,
      name: i18next.t('additionalPersonalData:lessThanSixMonths'),
    },
    { id: 'moreThanSixMonths', name: i18next.t('additionalPersonalData:moreThanSixMonths') },
  ]

  static workIndependentTimeOptions = [
    {
      id: LESS_THAN_TWELVE_MONTHS,
      name: i18next.t('additionalPersonalData:lessThanTwelveMonths'),
    },
    {
      id: 'moreThanTwelveMonths',
      name: i18next.t('additionalPersonalData:moreThanTwelveMonths'),
    },
  ]

  static confirm({ ...data } = {}) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/confirmation`, {
        ...data,
      })
      .then((res) => res.data.data)
  }

  static getLocationData(postalCode) {
    return (
      DealershipDataService.http()
        // ${API_URL}/sessions${DealershipDataService.creditUUID()}/insurances
        // `${MOCK_API_URL}/sessions${DealershipDataService.creditUUID()}/locations`
        .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}/insurances`, {
          params: { postalCode },
        })
        .then((res) => Location.fromJson(res.data.data.location))
    )
  }

  static fetchSettings() {
    return DealershipDataService.http()
      .get(
        `${API_URL}${
          DealershipDataService.creditUUID() ? `/sessions${DealershipDataService.creditUUID()}` : ''
        }/settings`
      )
      .then((res) => Settings.fromJson(res.data.data))
  }

  static sessionRequest() {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions`)
      .then((res) => res.data.data)
  }

  static getPrivacyAcceptanceStatus(json = null) {
    if (json) {
      return PrivacyAcceptance.fromJson(json)
    }

    return DealershipDataService.http()
      .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances/view`)
      .then((res) => PrivacyAcceptance.fromJson(res.data.data))
  }

  static sendPrivacyAcceptance(email, phoneNumber) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances`, {
        email,
        phoneNumber,
      })
      .then((res) => PrivacyAcceptance.fromJson(res.data.data))
  }

  static resendPrivacyAcceptance(email, phoneNumber) {
    return DealershipDataService.http()
      .post(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances/resend`, {
        email,
        phoneNumber,
      })
      .then((res) => res.data.data)
  }

  static confirmBureauCode(confirmationCode) {
    return DealershipDataService.http()
      .put(`${API_URL}/sessions${DealershipDataService.creditUUID()}/privacy-acceptances`, {
        confirmationCode,
      })
      .then((res) => res.data.data)
  }

  static getCarDataOptions(carYear, carBrand, carModel) {
    return (
      DealershipDataService.http()
        // `${API_URL}/sessions${DealershipDataService.creditUUID()}/insurances`
        .get(`${API_URL}/sessions${DealershipDataService.creditUUID()}/insurances`, {
          params: {
            year: carYear,
            make: carBrand,
            model: carModel,
          },
        })
        .then((res) => {
          return InsuranceCarData.fromJson(res.data?.data)
        })
    )
  }
}

export default DealershipDataService
