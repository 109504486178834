import React from 'react'
import RootStore from 'core/stores/RootStore'

const StoreContext = React.createContext(null)

const rootStore = new RootStore()

const RootStoreProvider = (props) => {
  return <StoreContext.Provider value={rootStore} {...props} />
}

const useRootStore = () => React.useContext(StoreContext)

export { RootStoreProvider, useRootStore }
