import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common from 'core/lang/common.json'
import identityValidator from 'core/lang/identityValidator.json'
import SEO from 'core/lang/SEO.json'
import privacyAuth from 'core/lang/privacyAuth.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  lng: 'es',

  ns: [],
  defaultNS: 'common',

  debug: process.env.REACT_APP_TRANSLATIONS_DEBUG === 'true',

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: true,
    useSuspense: false,
  },

  resources: {
    es: {
      common,
      SEO,
      identityValidator,
      privacyAuth,
    },
  },
})

export default i18n
