import { action, computed, observable, runInAction, makeObservable } from 'mobx'
import DealershipAuthService from 'modules/Dealership/services/DealershipAuthService'
import DealershipDataService from 'modules/Dealership/services/DealershipDataService'
import AsyncStore from 'core/stores/AsyncStore'
import { convertExpirationToMilliseconds } from 'core/util/utils'

class DealershipAuthStore extends AsyncStore {
  dealershipLogoutModal = false
  isAuthenticated = false
  sessionId = null
  hasSelectedDealershipAndBranch = false

  constructor(rootStore) {
    super()
    this.rootStore = rootStore
    this.init()

    makeObservable(this, {
      // observables
      dealershipLogoutModal: observable,
      isAuthenticated: observable,
      sessionId: observable,
      hasSelectedDealershipAndBranch: observable,
      // actions
      init: action,
      setExpirationTime: action,
      openLogoutModal: action,
      hideLogoutModal: action,
      authenticate: action,
      setSessionId: action,
      doBasicAuth: action,
      doFullyAuth: action,
      setSelectedDealershipAndBranch: action,
      clearSelectedDealershipAndBranch: action,
      logout: action,
      loginWithCredentials: action,
      createSession: action,
      updateSession: action,
      // computeds
      isFullyAuthenticated: computed,
    })
  }

  init() {
    try {
      if (DealershipAuthService.getSessionId()) {
        this.doBasicAuth()
        this.setExpirationTime(DealershipAuthService.getExpirationTime())
      }

      const sessionId = DealershipDataService.getCreditUUID()
      if (sessionId) {
        this.setSessionId(sessionId)
      }
    } catch (e) {
      this.onErrorRequest(e?.response)
    }
  }

  setExpirationTime(unixTime) {
    const expirationTime = convertExpirationToMilliseconds(unixTime)

    if (expirationTime > 0) {
      this.expirationTimeOut = setTimeout(() => {
        this.logout()
      }, expirationTime)
    } else {
      this.logout()
    }
  }

  openLogoutModal() {
    this.dealershipLogoutModal = true
  }

  hideLogoutModal() {
    this.dealershipLogoutModal = false
  }

  authenticate() {
    this.isAuthenticated = true
  }

  setSessionId() {
    this.sessionId = true
  }

  doBasicAuth() {
    this.authenticate()
  }

  get isFullyAuthenticated() {
    return this.sessionId && this.hasSelectedDealershipAndBranch
  }

  doFullyAuth(creditUUID) {
    try {
      DealershipDataService.setCreditUUID(creditUUID)
      this.setSelectedDealershipAndBranch()
      this.setSessionId(creditUUID)
    } catch (e) {
      this.onErrorRequest(e?.response)
    }
  }

  setSelectedDealershipAndBranch() {
    this.hasSelectedDealershipAndBranch = true
  }

  clearSelectedDealershipAndBranch() {
    this.hasSelectedDealershipAndBranch = false
  }

  async clearAuthentication() {
    try {
      this.isAuthenticated = false
      this.sessionId = null
      this.clearSelectedDealershipAndBranch()
      DealershipAuthService.deauthenticate()
    } catch (e) {
      this.onErrorRequest(e?.response)
    }
  }

  async logout() {
    await this.clearAuthentication()
    this.hideLogoutModal()
    clearTimeout(this.expirationTimeOut)
  }

  async loginWithCredentials(email, password) {
    await DealershipAuthService.authenticateWithCredentials(email, password)
    this.doBasicAuth()
    this.setExpirationTime(DealershipAuthService.getExpirationTime())
  }

  static sessionRequest(dealership, branch) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const { sessionId } = await DealershipDataService.sessionRequest()
        DealershipDataService.setCreditUUID(sessionId)
        await DealershipDataService.sendData({ dealership, branch })

        runInAction(() => {
          resolve(sessionId)
        })
      } catch (e) {
        this.onErrorRequest(e?.response)
        reject(e)
      }
    })
  }

  async createSession(dealership, branch) {
    try {
      return DealershipAuthStore.sessionRequest(dealership, branch).then((sessionId) => {
        this.doFullyAuth(sessionId)
      })
    } catch (e) {
      this.onErrorRequest(e?.response)
      return null
    }
  }

  async updateSession(dealership, branch) {
    try {
      return DealershipAuthStore.sessionRequest(dealership, branch).then((sessionId) => {
        this.doFullyAuth(sessionId)
      })
    } catch (e) {
      this.onErrorRequest(e?.response)
      return null
    }
  }
}

export default DealershipAuthStore
