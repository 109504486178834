import DealershipCarDataOption from 'modules/Dealership/models/DealershipCarDataOption'

class InsuranceCarData {
  constructor(years, makes, models, versions) {
    this.years = years
    this.brands = makes
    this.models = models
    this.versions = versions
  }

  static fromJson({ years, makes, models, versions }) {
    let parsedYears
    let parsedBrands
    let parsedModels
    let parsedVersions

    if (years) {
      parsedYears = years.map((year) => DealershipCarDataOption.fromJson(year))
    }
    if (makes) {
      parsedBrands = makes.map((make) => DealershipCarDataOption.fromJson(make))
    }
    if (models) {
      parsedModels = models.map((model) => DealershipCarDataOption.fromJson(model))
    }
    if (versions) {
      parsedVersions = versions.map((version) => DealershipCarDataOption.fromJson(version))
    }

    return new InsuranceCarData(parsedYears, parsedBrands, parsedModels, parsedVersions)
  }
}

export default InsuranceCarData
