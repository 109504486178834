import { observable, makeObservable } from 'mobx'
import SelectOption from 'core/models/SelectOption'

const DOWN_PAYMENT_BETWEEN_15_25 = '15_24.99'
const DOWN_PAYMENT_BETWEEN_25_35 = '25_34.99'
const DOWN_PAYMENT_UP_35 = 'up_35'

export default class Settings {
  maintenanceMode = false

  constructor(
    installments = [],
    maxVehicleValue = null,
    minVehicleValue = null,
    maxDownPaymentPercentage = null,
    minDownPaymentPercentage = null,
    minCreditAmount = null,
    rci = null,
    minAge = null,
    maxAge = null,
    rate = null,
    lifeInsurance = null,
    lojack = null,
    openingCommission = null,
    iva = null,
    jwtTtl = null,
    maintenanceMode = false,
    resendTime = null,
    maxCarAge = null,
    vehicleInsurance = null,
    workingModeOptions = [],
    ageAtJobOptions = []
  ) {
    this.installments = installments
    this.maxVehicleValue = maxVehicleValue
    this.minVehicleValue = minVehicleValue
    this.maxDownPaymentPercentage = maxDownPaymentPercentage
    this.minDownPaymentPercentage = minDownPaymentPercentage
    this.minCreditAmount = minCreditAmount
    this.rci = rci
    this.minAge = minAge
    this.maxAge = maxAge
    this.rate = rate
    this.lifeInsurance = lifeInsurance
    this.lojack = lojack
    this.openingCommission = openingCommission
    this.iva = iva
    this.jwtTtl = jwtTtl
    this.maintenanceMode = maintenanceMode
    this.resendTime = resendTime
    this.maxCarAge = maxCarAge
    this.vehicleInsurance = vehicleInsurance
    this.workingModeOptions = workingModeOptions
    this.ageAtJobOptions = ageAtJobOptions

    this.makeObservable()
  }

  setVehicleInsurance(value) {
    this.vehicleInsurance = value
  }

  installmentConfig(carAntiquity, downPaymentPercentage) {
    let taxRate = null
    // choose the correct rates
    if (carAntiquity <= 6) {
      if (downPaymentPercentage >= 15 && downPaymentPercentage < 25) {
        taxRate = this.rate[DOWN_PAYMENT_BETWEEN_15_25]
      } else if (downPaymentPercentage >= 25 && downPaymentPercentage < 35) {
        taxRate = this.rate[DOWN_PAYMENT_BETWEEN_25_35]
      } else {
        taxRate = this.rate[DOWN_PAYMENT_UP_35]
      }
    } else if (downPaymentPercentage >= 25 && downPaymentPercentage < 35) {
      taxRate = this.rate[DOWN_PAYMENT_BETWEEN_25_35]
    } else {
      taxRate = this.rate[DOWN_PAYMENT_UP_35]
    }

    return {
      installments: this.installments,
      insurances: {
        life: this.lifeInsurance,
        vehicle: this.vehicleInsurance,
      },
      charges: {
        lojack: this.lojack,
      },
      taxRate,
      iva: this.iva,
      openingCommission: this.openingCommission,
    }
  }

  static fromJson(json) {
    let maxVehicleValue = null
    let minVehicleValue = null
    let maxDownPaymentPercentage = null
    let minDownPaymentPercentage = null
    let minCreditAmount = null
    let rci = null
    let minAge = null
    let maxAge = null
    let maxCarAge = null

    let lifeInsurance = null
    let vehicleInsurance = null
    let lojack = null

    const {
      installments,
      simulation,
      rate,
      insurance,
      charges,
      openingCommission,
      iva,
      jwtTtl,
      maintenanceMode,
      privacyAcceptance,
      employeeTypeOptions = [],
      ageAtJobOptions = [],
    } = json

    if (simulation) {
      maxVehicleValue = simulation.maxVehicleValue
      minVehicleValue = simulation.minVehicleValue
      maxDownPaymentPercentage = simulation.maxDownPaymentPercentage
      minDownPaymentPercentage = simulation.minDownPaymentPercentage
      minCreditAmount = simulation.minCreditAmount
      rci = simulation.rci
      minAge = simulation.minAge
      maxAge = simulation.maxAge
      maxCarAge = simulation.maxCarAge
    }

    if (insurance) {
      lifeInsurance = insurance.life
      vehicleInsurance = insurance.vehicle
    }

    if (charges) {
      lojack = charges.lojack
    }

    return new Settings(
      installments,
      maxVehicleValue,
      minVehicleValue,
      maxDownPaymentPercentage,
      minDownPaymentPercentage,
      minCreditAmount,
      rci,
      minAge,
      maxAge,
      rate,
      lifeInsurance,
      lojack,
      openingCommission,
      iva,
      jwtTtl,
      maintenanceMode,
      privacyAcceptance?.resendTime,
      maxCarAge,
      vehicleInsurance,
      employeeTypeOptions.map((option) => SelectOption.fromJson(option)),
      ageAtJobOptions.map((option) => SelectOption.fromJson(option))
    )
  }

  makeObservable() {
    makeObservable(this, {
      // observables
      maintenanceMode: observable,
    })
  }
}
