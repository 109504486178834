import LocationOption from 'modules/Dealership/models/LocationOption'

class Location {
  constructor(zipCode, state, municipality, colonies) {
    this.zipCode = zipCode
    this.state = state
    this.municipality = municipality
    this.colonies = colonies
  }

  static fromJson({ postalCode, state, municipality, colonies }) {
    const parsedState = LocationOption.fromJson(state)
    const parsedMunicipality = LocationOption.fromJson(municipality)
    const parsedColonies = colonies.map((colony) => LocationOption.fromJson(colony))

    return new Location(postalCode, parsedState, parsedMunicipality, parsedColonies)
  }
}

export default Location
