// eslint-disable-next-line import/no-mutable-exports
let API_URL = process.env.REACT_APP_API_ROUTE || '/api'
const MOCK_API_URL = 'https://private-b968b-olxauto.apiary-mock.com/api'

if (process.env.NODE_ENV === 'development') {
  // API_URL = 'http://localhost:83/api'
  // API_URL = 'https://api-prod.olxautos.cl/api'
  API_URL = 'https://api.olxmex-qa.digbang.com/api'
  // API_URL = 'https://api-uat.olxautos.com.mx/api'
  // API_URL = 'http://192.168.1.105:83/api'
}

const STILL_ALIVE_MODAL_DIFF_TIME = 180000
const STILL_ALIVE_LOGOUT_DIFF_TIME = 30000

export { API_URL, STILL_ALIVE_MODAL_DIFF_TIME, STILL_ALIVE_LOGOUT_DIFF_TIME, MOCK_API_URL }
