import axios from 'axios'
import { API_URL } from 'core/services/config'
import DealershipDataService from 'modules/Dealership/services/DealershipDataService'
import JWT from 'core/models/JWT'

const DEALERSHIP_SESSION_ID = 'dealership-session'
const DEFAULT_EXPIRY_TIME = 1616590733

class DealershipAuthService {
  static setSessionId(value, expiry = DEFAULT_EXPIRY_TIME) {
    const item = { value, expiry }
    sessionStorage.setItem(DEALERSHIP_SESSION_ID, JSON.stringify(item))
  }

  static getSessionId() {
    const item = sessionStorage.getItem(DEALERSHIP_SESSION_ID)
    if (!item) {
      return null
    }
    return JSON.parse(item).value
  }

  static getExpirationTime() {
    const item = sessionStorage.getItem(DEALERSHIP_SESSION_ID)
    if (!item) {
      return null
    }
    return JSON.parse(item).expiry
  }

  static clearSessionId() {
    sessionStorage.removeItem(DEALERSHIP_SESSION_ID)
  }

  static authenticate(response) {
    const token = JWT.fromJson(response.data.data)
    DealershipAuthService.setSessionId(token.accessToken, token.expiration)
  }

  static authenticateWithCredentials(email, password) {
    return axios.post(`${API_URL}/sellers/login`, { email, password }).then((res) => {
      DealershipAuthService.authenticate(res)
      return res
    })
  }

  static deauthenticate() {
    DealershipAuthService.clearSessionId()
    DealershipDataService.removePersistedData()
  }
}

export default DealershipAuthService
