class LocationOption {
  constructor(value, label) {
    this.id = value
    this.value = label
  }

  static fromJson({ id, label }) {
    if (id && label) {
      return new LocationOption(id, label)
    }

    return null
  }

  static asJson(locationOption) {
    return {
      code: locationOption.id,
      description: locationOption.value,
    }
  }
}

export default LocationOption
