import { DEALERSHIP as DEALERSHIP_ROUTE } from 'modules/Dealership/routing/dealershipPaths'

export const LANDING = '/'
export const DEALERSHIP = `${DEALERSHIP_ROUTE}/*`
export const PRIVACY_AUTH = '/privacidad'
export const IDENTITY_VALIDATOR = '/validacion-identidad'

export default {
  DEALERSHIP,
}
