class DealershipInsuranceDescription {
  static fromJson(insuranceDescription) {
    let totalProtection = null
    let plusProtection = null
    let integralProtection = null

    if (insuranceDescription.totalProtection) {
      totalProtection = insuranceDescription.totalProtection
    }

    if (insuranceDescription.plusProtection) {
      plusProtection = insuranceDescription.plusProtection
    }

    if (insuranceDescription.integralProtection) {
      integralProtection = insuranceDescription.integralProtection
    }

    const descriptions = {
      totalProtection,
      plusProtection,
      integralProtection,
    }

    return descriptions
  }
}

export default DealershipInsuranceDescription
