class DealershipCarDataOption {
  constructor(id, value) {
    this.id = id
    this.value = value
  }

  static fromJson({ id, label }) {
    const fillId = id
    const fillValue = label || id
    return new DealershipCarDataOption(fillId, fillValue)
  }
}

export default DealershipCarDataOption
