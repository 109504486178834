import DealershipInsuranceProtection from 'modules/Dealership/models/DealershipInsuranceProtection'

class DealershipInsurance {
  constructor(id, integralProtection, totalProtection, plusProtection, logo) {
    this.id = id
    this.integralProtection = integralProtection
    this.totalProtection = totalProtection
    this.plusProtection = plusProtection
    this.logo = logo
  }

  static fromJson({ id, integralProtection, totalProtection, plusProtection, logo }) {
    let integral = null
    let total = null
    let plus = null

    if (integralProtection) {
      integral = DealershipInsuranceProtection.fromJson(integralProtection)
    }

    if (totalProtection) {
      total = DealershipInsuranceProtection.fromJson(totalProtection)
    }

    if (plusProtection) {
      plus = DealershipInsuranceProtection.fromJson(plusProtection)
    }

    return new DealershipInsurance(id, integral, total, plus, logo)
  }
}

export default DealershipInsurance
