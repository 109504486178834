import React from 'react'
import { IDENTITY_VALIDATOR, LANDING, PRIVACY_AUTH, DEALERSHIP } from './paths'

export const LandingComponent = React.lazy(() => {
  return import(/* webpackChunkName: "LandingMx" */ 'core/scenes/LandingMx')
})

export const DealershipModule = React.lazy(() =>
  import(/* webpackChunkName: "DealershipModule" */ 'modules/Dealership')
)

export const PrivacyAuthComponent = React.lazy(() =>
  import(/* webpackChunkName: "PrivacyAuth" */ 'core/scenes/PrivacyAuth')
)

export const IdentityValidatorComponent = React.lazy(() =>
  import(/* webpackChunkName: "IdentityValidator" */ 'core/scenes/IdentityValidator')
)

export const routes = [
  {
    path: PRIVACY_AUTH,
    exact: false,
    name: 'privacyAuth',
    component: <PrivacyAuthComponent />,
  },
  {
    path: IDENTITY_VALIDATOR,
    exact: false,
    name: 'identityValidator',
    component: <IdentityValidatorComponent />,
  },
  {
    path: LANDING,
    exact: true,
    name: 'landing',
    component: <LandingComponent />,
  },
  {
    path: DEALERSHIP,
    exact: false,
    name: 'dealership',
    component: <DealershipModule />,
  },
]
