/* eslint-disable no-param-reassign */
import axios from 'axios'

const axiosInterceptors = () => {
  // Handles all 403 requests
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response !== undefined && error.response.status > 403) {
        error.response.unknown = true
      }

      return Promise.reject(error)
    }
  )

  // Adds bearer to all API requests
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        'X-API-KEY': process.env.REACT_APP_AUTHENTICATION,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }

      // Do something before request is sent
      return config
    },
    (error) => Promise.reject(error)
  )
}

export default axiosInterceptors
