class DealershipInsuranceProtection {
  constructor(id, amount) {
    this.id = id
    this.amount = parseInt(amount, 10)
  }

  static fromJson({ id, value } = {}) {
    return new DealershipInsuranceProtection(id, value.amount)
  }
}

export default DealershipInsuranceProtection
