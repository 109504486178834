import DealershipInsurance from 'modules/Dealership/models/DealershipInsurance'
import DealershipDescriptions from 'modules/Dealership/models/DealershipInsuranceDescription'

class DealershipInsurances {
  constructor() {
    this.insurances = []
    this.descriptions = []
  }

  setInsuranceData(insurances) {
    insurances.forEach((insurance) => this.insurances.push(DealershipInsurance.fromJson(insurance)))
  }

  setDescriptions(descriptions) {
    this.descriptions.push(DealershipDescriptions.fromJson(descriptions))
  }

  static fromJson({ insurances, descriptions }) {
    const dealershipInsurances = new DealershipInsurances()

    if (insurances && insurances.length >= 0) {
      dealershipInsurances.setInsuranceData(insurances)
    }

    if (descriptions != null) {
      dealershipInsurances.setDescriptions(descriptions)
    }

    return dealershipInsurances
  }
}

export default DealershipInsurances
