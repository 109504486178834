export const DEALERSHIP = '/concesionaria'

const SUBDOMAIN_ROUTE = `${DEALERSHIP}/`

// dealership routes
export const DEALERSHIP_LOGIN = `${SUBDOMAIN_ROUTE}login`
export const DEALERSHIP_HOME = `${SUBDOMAIN_ROUTE}`
export const DEALERSHIP_CAR_DATA = `${SUBDOMAIN_ROUTE}datos-auto`
export const DEALERSHIP_RESULT = `${SUBDOMAIN_ROUTE}resultado`
export const DEALERSHIP_PRE_CREDIT_APPROVED = `${SUBDOMAIN_ROUTE}credito-pre-aprobado`
export const DEALERSHIP_PRE_CREDIT_REJECTED = `${SUBDOMAIN_ROUTE}credito-rechazado`
export const DEALERSHIP_ADDITIONAL_PERSONAL_DATA = `${SUBDOMAIN_ROUTE}datos-laborales`
export const DEALERSHIP_PERSONAL_DATA = `${SUBDOMAIN_ROUTE}datos-personales`
export const DEALERSHIP_PRIVACY_POLICIES = `${SUBDOMAIN_ROUTE}politicas-privacidad`
export const DEALERSHIP_VERIFYING = `${SUBDOMAIN_ROUTE}verificando`
export const DEALERSHIP_PRE_CREDIT_SEND_LATER = `${SUBDOMAIN_ROUTE}enviaremos-informacion`
export const DEALERSHIP_INSURANCES = `${SUBDOMAIN_ROUTE}seguros`
export const DEALERSHIP_SUMMARY_SENT = `${SUBDOMAIN_ROUTE}envio-cotizacion`

export const dealershipPath = (route) => route.replace(SUBDOMAIN_ROUTE, '')

export default {
  DEALERSHIP,
  DEALERSHIP_LOGIN,
  DEALERSHIP_PRIVACY_POLICIES,
  DEALERSHIP_RESULT,
  DEALERSHIP_PRE_CREDIT_APPROVED,
  DEALERSHIP_PRE_CREDIT_REJECTED,
  DEALERSHIP_ADDITIONAL_PERSONAL_DATA,
  DEALERSHIP_PERSONAL_DATA,
  DEALERSHIP_VERIFYING,
  DEALERSHIP_PRE_CREDIT_SEND_LATER,
  DEALERSHIP_HOME,
  DEALERSHIP_CAR_DATA,
  DEALERSHIP_SUMMARY_SENT,
}
